
import React from 'react';
import { CardColumns, Row } from 'react-bootstrap';
import img8 from './img/icloudbypass.png';
import {Badge,Image,Card,Button} from 'react-bootstrap'
import mac from './img/icloudbypass.png';
import AdSense from 'react-adsense';

function Center() {
    return(

        <div style={{backgroundColor: '#F8F8F8'}}  class="inner">
<h1>Bypass iCould Deveice</h1>
<h3>Suppored A9-A11.</h3>

{/* imgage yahan Ayegi  */}
<div>
<Image src={ img8}fluid />
</div>
<div>



</div>

<div style={{ flex:1,flexDirection: 'row' ,justifyContent: 'space-between', padding:30}} >

<a style={{padding:10}} href="/about"> Leam More</a>
</div>

<div>

<div class="alert alert-info" role="alert">
We Dont Own Any Copyrights for the following Cydia Tweak <strong>DarkRa1n</strong>  l <a href="https://twitter.com/ios_euphoria?s=21&t=ruSHIzhXnTCT9mqvCQVYGg" class="alert-link">Website</a>
</div>

<div class="alert alert-danger alert-dismissible fade show" role="alert">
  <strong>Note!</strong> If Any Damnage Happens We are Not responsible 
  <Badge variant="primary">   New</Badge>

    
    </div >

  

</div>
<div>

</div>
        </div>


    )
}

export default Center;