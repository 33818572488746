
import './App.css';
import ReactGa from 'react-ga'
import { useEffect } from 'react';
import HomePage from './HomePage'
import HttpsRedirect from 'react-https-redirect';
import PagenotFound from './PageNotFound/Pagenotfound'
import About from './About/About'

import {



  BrowserRouter as Router,
 Switch,
 Route

} from 'react-router-dom'
function App() {
//UA-81746914-4
  useEffect(()=>{
ReactGa.initialize('UA-81746914-4')
ReactGa.pageview(window.location.pathname + window.location.search)
},[])

  return (
    <>

<HttpsRedirect>
<Router>
    
     <Switch> 
       <Route path='/' exact component={HomePage}/>
       <Route path='/about'  component={About}/> 
      <Route  path = '*' component={PagenotFound}/>
     </Switch>
   </Router>
</HttpsRedirect>
 




     

  
     
  
    </>
  );
}

export default App;
